import { ref } from "vue";
import type { Ref } from "vue";

interface UseAccordionReturn {
  openIds: Ref<string[]>;
  toggle: (id: string) => void;
}

// Define a reactive reference that will hold the ID of the currently open accordion item
const openIds = ref<Array<string>>([]);
const useAccordion = (allowMultiple: boolean): UseAccordionReturn => {
  const toggle = (id: string): void => {
    const index = openIds.value.indexOf(id);

    if (index === -1) {
      // If the given ID is not currently open and we allow multiple items to be open,
      // add the ID to the list. Otherwise, replace the list with only this ID.
      if (allowMultiple) {
        openIds.value.push(id);
      } else {
        openIds.value = [id];
      }
    } else {
      // If the given ID is currently open, remove it from the list to close it.
      openIds.value.splice(index, 1);
    }
  };

  return { openIds, toggle };
};

export default useAccordion;
