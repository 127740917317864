<template>
  <article
    class="ui-transition-all ui-duration-200 ui-w-full last:ui-border-b-[1px]"
    :class="
      isDark
        ? 'last:ui-border-grey-dark ui-bg-charcoal-default'
        : 'last:ui-border-grey-light'
    "
  >
    <button
      @click="toggle(id)"
      class="ui-px-xs ui-w-full ui-flex ui-items-center ui-justify-between"
      :class="[
        borderTop && 'ui-border-t-[1px]',
        isExpanded && !isFilter && 'ui-border-b-[1px]',
        isExpanded && !isDark && !isFilter && 'ui-bg-grey-lighter',
        isDark ? 'first:ui-border-grey-dark' : 'first:ui-border-grey-light',
        !isDark &&
          !isFilter &&
          'hover:ui-bg-neutral-lighter active:ui-bg-neutral-lighter',
        disabled && 'ui-opacity-40 ui-pointer-events-none',
        isFilter ? 'ui-py-sm' : 'ui-py-2xs',
      ]"
      :data-test-id="`plpExpandBtn-${label}`"
    >
      <Text
        tag="p"
        size="md"
        :class="[
          isDark ? 'ui-text-white' : 'ui-text-charcoal-default',
          isFilter ? 'ui-py-none' : 'ui-py-3xs',
        ]"
        :weight="isFilter ? 'bold' : 'semi'"
        align="left"
      >
        {{ label }}
      </Text>

      <span
        :class="[
          'ui-ml-xs',
          isExpanded && 'ui-rotate-180',
          isDark ? 'ui-text-white' : 'ui-text-mkm-blue-light',
          isFilter && '!ui-text-charcoal-default',
        ]"
        class="ui-transition-transform ui-duration-200"
      >
        <Icon name="chevron-down" :size="16" />
      </span>
    </button>
    <div
      :style="isExpanded && accordionContent && `height: ${accordionHeight}px;`"
      class="ui-h-[0px] ui-px-xs ui-overflow-hidden ui-transition-[height] ui-duration-300"
      :class="isDark ? 'ui-text-white' : 'ui-text-charcoal-default'"
      ref="accordionContent"
    >
      <slot />
    </div>
  </article>
</template>

<script setup lang="ts">
import Icon from "../Icon/Icon.vue";
import Text from "../Typography/Text/Text.vue";
import type { AccordionItemProps } from "./types";
import useAccordion from "../../composables/useAccordion";
import { ref, onMounted, watchEffect, nextTick } from "vue";

const props = defineProps<AccordionItemProps>();

const { openIds, toggle } = useAccordion(props.openMultiple);

const isExpanded = ref(props.initiallyExpanded ?? false);
const accordionContent = ref<HTMLElement | null>(null);
const accordionHeight = ref<number>(0);

if (props.initiallyExpanded && !openIds.value.includes(props.id)) {
  openIds.value.push(props.id);
  isExpanded.value = true;
}

onMounted(() => {
  if (accordionContent.value) {
    accordionHeight.value = accordionContent.value.scrollHeight;
  }
});

watchEffect(async () => {
  isExpanded.value = openIds.value.includes(props.id);

  if (isExpanded.value && accordionContent.value) {
    await nextTick(); // Wait for the DOM to update
    accordionHeight.value = accordionContent.value.scrollHeight;
  } else {
    accordionHeight.value = 0;
  }
});
</script>
